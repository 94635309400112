import React, { Component } from 'react';
import { addDays } from 'date-fns';
import moment from 'moment';

import axios from 'axios';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const s_akey = "9EXswpW5wTavJruigExzZpPqH/rN";
const s_skey = "7ELiwYe8gzbtOb++gkxvJ4ywR7uRKMECDW9OouIqPg==/rN";
const s_lykill = "00fb02ad-2a6a-4769-be53-23dfde43188c";
const s_tungumal = "IS";

const s_man = new Date().getMonth();
const s_ar = new Date().getFullYear();
const s_poductid = 0;

const newstartDate = addDays(new Date(), 2);
const newendDate = addDays(new Date(), 8);

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();
let MONTHS = {}, YEARS = [CURRENT_YEAR];
for (let i = 1; i <= 12; i++) {
    MONTHS[i] = i.toString().length === 1 ? `0${i}` : i.toString();
    YEARS.push(YEARS[0] + i);
}

export default class BokunarvelView extends Component {
    static propTypes = {
        renderDay: PropTypes.func,
        width: PropTypes.string,
        style: PropTypes.object,
        titleTextStyle: PropTypes.object,
        dayNameTextStyle: PropTypes.object,
        dayTextStyle: PropTypes.object,
        activeDayStyle: PropTypes.object,
        inactiveDayStyle: PropTypes.object,
        onMonthChange: PropTypes.func,
    };

    static defaultProps = { renderDay: date => {}, onMonthChange: () => {} };
    state = {
        date: moment().startOf('month'),
        posts: [],
        pickups: [],
        userstage:1,
        isLoaded: false,
        tourname:"",
        pickuptime:"",
        pricePerSeat:2,
        priceSoloFee:0,
        priceChildren:0,
        totalPassagers:2,
        perSeatFee:0,
        startprice:0,
        soloFee:0,
        ChildrenFee:0,
        totalFee:0,
        ispricePerSeat: " active",
        ispriceChildren: " disabled",
        ispriceSoloFee: " disabled",
        v_ferd: this.props.bookid,
        v_id: "",
        v_dagur: "",
        verdavoru: 0,
        ispickup: 0,
        pickup: 0,
        pickupname: "I don't need pick-up",
        isdropoff: 0,
        dropoff: 0,
        dropoffname: "",
        startDate: newstartDate,
        endDate: newendDate,
        s_participants: "participants h",
        s_veljadags: "velja_dags h",
        s_participants_btn: "",
        s_veljadags_btn: "",
        access_token: "",
        session_id: "",
        cardnumber:"",
        cardmonth:"12",
        cardyear:"23",
        cvv:"",
        cardname:"",
        pax_name:"",
        pax_email:"",
        pax_phone:"",
        formError:0,
        FormMessage:"",
        pax_name_v:"ath_input",
        pax_email_v:"ath_input",
        pax_phone_v:"ath_input",
        pax_name_vt:"",
        pax_email_vt:"",
        pax_phone_vt:"",
        cardname_v:"ath_input",
        cardnumber_v:"ath_input",
        cardmonth_v:"ath_input",
        cardyear_v:"ath_input",
        cvv_v:"ath_input",
        cardname_vt:"",
        cardnumber_vt:"",
        cardmonth_vt:"",
        cardyear_vt:"",
        cvv_vt:"",
        cardType: "visa",
        getCardNumberProps:"",
        bokunarcode:"",
        loading:false,
        loading_btn:"btn_continue",
        discount:0,
        giftcard:"",
        gifttype:0,
        gift_msg:"",
        validGiftcard:false,
        discounttotal:0,
        agreement:true,
        agreement_error:""
    };

    componentDidMount() {
        var config = {'Content-Type': 'application/json'}
        const json = JSON.stringify({start: moment(newstartDate).format("YYYY-MM-DD"), end: moment(newendDate).format("YYYY-MM-DD"), tungumal: s_tungumal, poductid: this.props.bookid,akey: s_akey,skey: s_skey,lykill: s_lykill,man:s_man,ar:s_ar});
        axios.post('https://api.airmango.is/availabilitiesdates/'+this.props.bookid, json, {headers: config})
        .then(res => this.setState({
            perSeatFee:res.data.data.priceperperson,
            startprice:res.data.data.verd,
            totalFee: res.data.data.verd + (res.data.data.priceperperson * 3),
            posts: res.data.data.availability,
            pickups: res.data.data.pickuplist,
            tourname: res.data.data.tour,
            pickuptime: res.data.data.pickuptime,
            isLoaded: true
        }))
        .catch(error => {const response = error.response;})
    }

    componentWillUnmount() {
        //window.removeEventListener('resize', this._handleWindowResize);
    }

    _handleApplyCode() {
        const { giftcard, v_dagur } = this.state;
        var config = {'Content-Type': 'application/json'}
        const json = JSON.stringify({
            code:giftcard,
            traveldate: v_dagur
        });
        axios.get('https://api.airmango.is/giftcards/get/'+giftcard+'/'+v_dagur, json, {headers: config})
        .then(res => this.changePrice(res.data.data.giftcard[0].type, res.data.data.giftcard[0].amount))
        .catch(error => this.setState({gift_msg: "Code not found or valid!", validGiftcard:false }))

    }

    addToPayment() {
        this.setState({ loading: true, loading_btn:"btn_continue disabled", FormMessage:"" });
        let errorcount = 0;
        const { session_id, cardname, cardnumber, cardmonth, cardyear, cvv, pax_name, pax_email, pax_phone, cardType, pickuptime, agreement } = this.state;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (regex.test(pax_email)) {
            this.setState({ pax_email_v:"ath_input", pax_email_vt:""  });
        } else {
            errorcount++;
            this.setState({ pax_email_v:"ath_input error", pax_email_vt:"Not a valid email address"  });
        }

        if (!agreement) {
            this.setState({ agreement_error:"You need to accept the terms and conditions!"  });
            errorcount++;
        } else {
            this.setState({ agreement_error:""  });
        }

        if (pax_name.length >= 4) {
            this.setState({ pax_name_v:"ath_input", pax_name_vt:""  });
        } else {
            errorcount++;
            this.setState({ pax_name_v:"ath_input error", pax_name_vt:"This field is required"  });
        }

        if (cardnumber.length === 16) {
            this.setState({ cardnumber_v:"ath_input", cardnumber_vt:""  });
        } else {
            errorcount++;
            this.setState({ cardnumber_v:"ath_input error", cardnumber_vt:"Not correct card"  });
        }

        if (isValidPhoneNumber(pax_phone)) {
            this.setState({ pax_phone_v:"ath_input", pax_phone_vt:""  });
        } else {
            errorcount++;
            this.setState({ pax_phone_v:"ath_input error", pax_phone_vt:"Not a valid phone number"  });
        }

        this.setState({ formError:errorcount  });

        if (errorcount == 0) {
            var config = {'Content-Type': 'application/json'}
            let newgildi = cardmonth + "/" + cardyear;
            let mybc = "00fb02ad-2a6a-4769-be53-23dfde43188c";
            const json = JSON.stringify({
                nafn: pax_name,
                cardname: cardname,
                EmailAddress: pax_email,
                PhoneNumber: pax_phone,
                card: cardnumber,
                cart_id: session_id,
                cvc: cvv,
                gildi: newgildi,
                cardmonth: cardmonth,
                cardyear: cardyear,
                cardtype: cardType,
                bc: mybc,
                pickuptime: pickuptime,
            });

            axios.post('https://api.airmango.is/cart/confirmbg', json, {headers: config})
            .then(res => {
                if (res.data.statusCode === 201) {
                    let bookCode = res.data.data.bookingcode;
                    this.setState({ userstage: 4, bokunarcode: bookCode})
                }
            })
            .catch(error => {
                const response = error.response;
                console.log(response.data.statusCode);
                if (response.data.statusCode === 401) {
                    let Villunumer = response.data.data.Villunumer;
                    let errormsg = "Error geting payment from card";

                    if (Villunumer == "INVALID_CARD_NUMBER") {
                        errormsg = "Invalid card number";
                    }

                    if (Villunumer == "ERROR_PROCESSING_CARD - [15]") {
                        errormsg = "Error processing card";
                    }

                    this.setState({ loading: false, loading_btn:"btn_continue", FormMessage:errormsg });
                }
            })
        } else {
            this.setState({ loading: false, loading_btn:"btn_continue", FormMessage:"" })
        }
    }

    changePrice(gifttype, discount) {
        const {totalFee } = this.state;
        let newEquation = totalFee - (totalFee * discount/100);
        let discounttotal = totalFee * discount/100;
        this.setState({
            gifttype: gifttype,
            discount: discount,
            validGiftcard:true,
            gift_msg: "",
            discounttotal: discounttotal,
            totalFee: newEquation
        })
    }

    addToBasket() {
        this.setState({ loading: true, loading_btn:"btn_continue disabled" })
        const { v_ferd, v_id, v_dagur, totalPassagers, verdavoru, totalFee, ispickup, pickup, pricePerSeat, priceChildren, perSeatFee, soloFee, ChildrenFee } = this.state;
        let string_moguleikar = "";
        if (totalPassagers === 1) {
            string_moguleikar = pricePerSeat + ":" + perSeatFee + ":0,1:" + soloFee + ":0," + priceChildren + ":" + ChildrenFee + ":0";
        } else {
            string_moguleikar = pricePerSeat + ":" + perSeatFee + ":0,0:" + soloFee + ":0," + priceChildren + ":" + ChildrenFee + ":0";
        }

        var config = {'Content-Type': 'application/json'}
        const json = JSON.stringify({
            v_ferd: v_ferd,
            v_id: v_id,
            v_dagur: v_dagur,
            valin_fjoldi: totalPassagers,
            verdavoru: verdavoru,
            samtalsverd: totalFee,
            moguleikar: string_moguleikar,
            ispickup:ispickup,
            pickup:pickup,
            xtra:0,
            xtraverd_str:""
        });

        axios.post('https://api.airmango.is/cart/create', json, {headers: config})
        .then(res => {
            if (res.data.statusCode === 201) {
                this.setState({ access_token: res.data.data.access_token, session_id:res.data.data.session_id, userstage: 3, loading: false, loading_btn:"btn_continue" })

            } else {
                this.setState({ loading: false, loading_btn:"btn_continue" })
            }
        })
        .catch(error => {const response = error.response;})
    }

    getPickup() {
        const { pickups } = this.state;
        return pickups.map((pickup) => {
            return <option value={pickup.id} key={pickup.id}>{pickup.title}</option>;
        });
    }

    handlePickupChange = event => {
        let newpickupname = event.target.options[event.target.selectedIndex].text;
        let pickupname = "";
        let newpickup = event.target.value;
        let ispickupnew = 0;
        if (newpickup == 0) {
            ispickupnew = 0;
            pickupname = "";
        } else {
            ispickupnew = 1;
            pickupname = newpickupname;
        }
        this.setState({ pickup: newpickup, ispickup: ispickupnew, pickupname: pickupname});
    }

    handleDropoffChange = event => {
        let newdropoffname = event.target.options[event.target.selectedIndex].text;
        let newdropoff = event.target.value;
        let dropoffname = "";
        let isdropoffnew = 0;
        if (newdropoff == 0) {
            isdropoffnew = 0;
            dropoffname = "";
        } else {
            isdropoffnew = 1;
            dropoffname = newdropoffname;
        }
        this.setState({ dropoff: newdropoff, isdropoff: isdropoffnew, dropoffname: dropoffname});
    }

    handleSelect = ranges => {
        this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
        var config = {'Content-Type': 'application/json'}
        const json = JSON.stringify({start: moment(ranges.selection.startDate).format("YYYY-MM-DD"), end: moment(ranges.selection.endDate).format("YYYY-MM-DD"), tungumal: s_tungumal,poductid: this.props.bookid,akey: s_akey,skey: s_skey,lykill: s_lykill,man:s_man,ar:s_ar});
        axios.post('https://api.airmango.is/availabilitiesdates/'+this.props.bookid, json, {headers: config})
        .then(res => this.setState({ posts: res.data.data.availability, isLoaded: true }))
        .catch(error => {const response = error.response;})
    }

    addSeat = action => {
        const { priceChildren, pricePerSeat, priceSoloFee, perSeatFee, soloFee, ChildrenFee, startprice } = this.state;
        
        let newpricePerSeat;
        let isactive = " active";
        if (pricePerSeat === 2 && action == "-1") {
            console.log(pricePerSeat);
            if (pricePerSeat < 3) {
                newpricePerSeat = pricePerSeat;
            }
        } else {
            newpricePerSeat = pricePerSeat + action;
        }
        let pax = 0;
        pax = priceChildren + newpricePerSeat;
        let newperSeatFee = perSeatFee * newpricePerSeat;
        if (pax===1) {
            newperSeatFee = newperSeatFee + soloFee;
        }
        let newsoloFee = soloFee * priceSoloFee;
        let newChildrenFee = ChildrenFee * priceChildren;
        let newtotalFee = newChildrenFee + newsoloFee + newperSeatFee + startprice + perSeatFee;
        if (newpricePerSeat === 0) {
            isactive = " disabled";
        }
        let newtotalPassagers = newpricePerSeat + priceChildren + priceSoloFee;
        this.setState({ pricePerSeat: newpricePerSeat, totalPassagers: newtotalPassagers, totalFee: newtotalFee, ispricePerSeat:isactive });
    }

    _showDates = days => {
        this.setState({ s_veljadags: "velja_dags", s_participants: "participants h", s_participants_btn:"V", s_veljadags_btn:"X" });
    }

    _showPax = days => {
        this.setState({ s_veljadags: "velja_dags h", s_participants: "participants", s_participants_btn:"X", s_veljadags_btn:"V" });
    }

    _handlePaxChange = days => {
        this.setState({ s_veljadags: "velja_dags h", s_participants: "participants h", s_participants_btn:"V", s_veljadags_btn:"V" });
    }

    _handleDatesChange = days => {
        const { startDate, endDate } = this.state;
        var config = {'Content-Type': 'application/json'}
        const json = JSON.stringify({start: moment(startDate).format("YYYY-MM-DD"), end: moment(endDate).format("YYYY-MM-DD"), tungumal: s_tungumal,poductid: this.props.bookid,akey: s_akey,skey: s_skey,lykill: s_lykill,man:s_man,ar:s_ar});
        axios.post('https://api.airmango.is/availabilitiesdates/'+this.props.bookid, json, {headers: config})
        .then(res => this.setState({ posts: res.data.data.availability, isLoaded: true }))
        .catch(error => {const response = error.response;})

        this.setState({ s_veljadags: "velja_dags h", s_participants: "participants h", s_participants_btn:"V", s_veljadags_btn:"V" });
    }

    _handleChoose(days, dagur) {
        this.setState({ userstage: 2, v_id: days, v_dagur: dagur  });
    }

    _handleCancel = days => {
        this.setState({ userstage: 1 });
    }

    _handleName = e => {
        if (e.length >= 4) {
            this.setState({ pax_name_v:"ath_input", pax_name_vt:"", pax_name: e  });
        } else {
            this.setState({ pax_name: e  });
        }
    }

    _handleEmail = e => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (regex.test(e)) {
            this.setState({ pax_email: e, pax_email_v:"ath_input", pax_email_vt:""  });
        } else {
            this.setState({ pax_email: e  });
        }
    }

    _handlePhone = e => {
        if (e === undefined) {
            this.setState({ pax_phone: e  });
        } else {
            if (isValidPhoneNumber(e)) {
                this.setState({ pax_phone: e, pax_phone_v:"ath_input", pax_phone_vt:""  });
            } else {
                this.setState({ pax_phone: e  });
            }
        }
    }

    _handleCVV = e => {
        this.setState({ cvv: e  });
    }

    _handleExpMon = e => {
        this.setState({ cardmonth: e  });
    }

    _handleExpYear = e => {
        this.setState({ cardyear: e  });
    }

    _handleCardName = e => {
        this.setState({ cardname: e  });
    }

    _handleGiftCard = e => {
        this.setState({ giftcard: e  });
    }

    getCardType = number => {
        let re = new RegExp("^4");
        if (number.match(re) != null) return "visa";
        re = new RegExp("^(34|37)");
        if (number.match(re) != null) return "amex";
        re = new RegExp("^5[1-5]");
        if (number.match(re) != null) return "mastercard";
        re = new RegExp("^6011");
        if (number.match(re) != null) return "discover";
        return "visa";
    };

    handleChange = (event, type) => {
        let { value } = event.target;
        if (type === "cardnumber") {
            value = value.replace(/ /gi, "");
            if (isNaN(value)) {
                return;
            } else {
                const cardType = this.getCardType(value);
                this.setState({ [type]: value, cardType });
            }
        } else if (type === "cardName") {
            var regName = /^[a-zA-Z\s]*$/;
            if (!regName.test(value)) {
            } else {
                this.setState({ [type]: value });
            }
        } else if (type === "cardmonth") {
            value = Number(value);
            this.setState(prevState => ({
                [type]: value,
                toggleMonth: !prevState.toggleMonth
            }));
        } else if (type === "cardyear") {
            value = Number(value);
            const { cardMonth } = this.state;
            if (value === CURRENT_YEAR && cardMonth <= CURRENT_MONTH) {
                this.setState(prevState => ({
                    cardMonth: 0,
                    cardyear: value,
                    toggleYear: !prevState.toggleYear,
                    toggleMonth: !prevState.toggleMonth
                }));
            } else {
                this.setState(prevState => ({
                    [type]: value,
                    toggleYear: !prevState.toggleYear
                }));
            }
        } else if (type === "cvv") {
            value = value.replace(/ /gi, "");
            if (isNaN(value)) {
                return;
            } else {
            this.setState({ [type]: value });
            }
        } else if (type === "terms") {
            this.setState({ agreement: event.target.checked });
        }
    };

    formatCardNumber = value => {
        let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        let matches = v.match(/\d{4,16}/g);
        let match = (matches && matches[0]) || "";
        let parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(" ");
        } else {
            return value;
        }
    };

    render() {
        const {
            loading_btn,
            loading,
            bokunarcode,
            pickupname,
            tourname,
            cardnumber_v,
            cardnumber_vt,
            cardType,
            pax_name_vt,
            pax_email_vt,
            pax_phone_vt,
            pax_name_v,
            pax_email_v,
            pax_phone_v,
            pax_name,
            pax_email,
            pax_phone,
            cardnumber,
            cardname,
            cardmonth,
            cardyear,
            cvv,
            userstage,
            s_veljadags,
            s_participants,
            posts,
            startDate,
            endDate,
            isLoaded,
            pricePerSeat,
            startprice,
            totalPassagers,
            perSeatFee,
            soloFee,
            totalFee,
            ispricePerSeat,
            v_dagur,
            ispickup,
            FormMessage,
            pickuptime,
            discount,
            giftcard,gift_msg, validGiftcard, discounttotal, agreement, agreement_error
        } = this.state;

        const selectionRange = {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
            color: "#9FC700",
            rangeColors: "#000000",
        }

        if (isLoaded) {

            if (userstage === 1) {
                if (posts) {
                    return (
                    <div className='bokunarvel'>
                        <h1 className='bokunarvel_h1'>Check availability</h1>
                        <div className="adal_valmoguleikar">
                            <div className="dates_val" onClick={() => this._showDates()}>
                                <strong>Dates</strong>
                                {moment(startDate).format("D MMM")} - {moment(endDate).format("D MMM")}
                                <div className='arrow'></div>
                            </div>
                            <div className="participants_val" onClick={() => this._showPax()}>
                                <strong>Guests</strong>
                                {totalPassagers} pax
                                <div className='arrow'></div>
                            </div>
                        </div>

                        <div className={s_veljadags}>
                            <DateRangePicker
                                onChange={this.handleSelect}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                minDate={moment().toDate()}
                                ranges={[selectionRange]}
                                preventSnapRefocus={true}
                            />
                            <button className='change_dates_btn' onClick={() => this._handlePaxChange()}>X</button>
                        </div>
                        <div className={s_participants}>
                            <div className="participants_line">
                                <div className="name">Price per seat <small>Minimum pax 2</small></div>
                                <div className="number">
                                    <span className={"minus" + ispricePerSeat} onClick={() => this.addSeat(-1)}> - </span>
                                    <input type="text" data-price={perSeatFee} readOnly value={pricePerSeat}/>
                                    <span className="plus" onClick={() => this.addSeat(+1)}> + </span>
                                </div>
                                
                            </div>
                            <button className='change_dates_btn' onClick={() => this._handleDatesChange()}>X</button>
                        </div>
                        <h2>Choose a date </h2>
                        {posts.map(post => (
                            post.availability >= totalPassagers
                            ? (<div className="frambod" data-date={post.dagur} key={post.id} data-availability={post.availability} >
                                    <div className='hvenaer'><strong>{moment(post.dagur).format("ddd, MMM D")}</strong><br/> {pickuptime}</div>
                                    <div className="verdid"><strong>Travellers:</strong> {totalPassagers}<br/>{parseFloat(totalFee).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                                    <div className='velja'><button onClick={() => this._handleChoose(post.id, post.dagur)}>Choose</button></div>
                                </div>)
                            : (<div className="ekkertframbod" data-date={post.dagur} key={post.id} data-availability={post.availability} >
                                    <div className='hvenaer'><strong>{moment(post.dagur).format("ddd, MMM D")}</strong><br/> {pickuptime}</div>
                                    <div className="verdid"><strong>Travellers:</strong> {totalPassagers}<br/></div>
                                    <div className='velja'><button disabled="disabled" className='btn_disabled'>Choose</button></div>
                                </div>)
                        ))}
                    </div>
                    );
                } else {
                    return (
                        <div className='bokunarvel'>
                        <div className="adal_valmoguleikar">
                            <div className="dates_val" onClick={() => this._showDates()}>
                                <strong>Dates</strong>
                                {moment(startDate).format("D MMM")} - {moment(endDate).format("D MMM")}
                                <div className='arrow'></div>
                            </div>
                            <div className="participants_val" onClick={() => this._showPax()}>
                                <strong>Travellers</strong>
                                {totalPassagers} travellers
                                <div className='arrow'></div>
                            </div>
                        </div>

                        <div className={s_veljadags}>
                            <DateRangePicker
                                onChange={this.handleSelect}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                minDate={moment().toDate()}
                                ranges={[selectionRange]}
                                preventSnapRefocus={true}
                            />
                            <button className='change_dates_btn' onClick={() => this._handlePaxChange()}>X</button>
                        </div>
                        <div className={s_participants}>
                            <div className="participants_line">
                                <div className="name">Pax <small>Adult {perSeatFee} ISK</small></div>
                                <div className="number">
                                    <span className={"minus" + ispricePerSeat} onClick={() => this.addSeat(-1)}> - </span>
                                    <input type="text" data-price={perSeatFee} readOnly value={pricePerSeat}/>
                                    <span className="plus" onClick={() => this.addSeat(+1)}> + </span>
                                </div>
                            </div>
                            <button className='change_dates_btn' onClick={() => this._handleDatesChange()}>X</button>
                        </div>
                        <h2>Choose a date</h2>
                        <p>No availability from these dates</p>
                    </div>
                    )
                }
            } else if (userstage === 2) {
                return (
                    <div className='bokunarvel'>
                        <h3>{tourname}</h3>
                        {moment(v_dagur).format("dddd, MMMM D YYYY")} - {pickuptime}<br/><br/>
                        <h3>Order summary</h3>
                        <div className="basket">
                            <div className="alpha">Passagers</div>
                            <div className="gamma">{totalPassagers}</div>
                            <div className="alpha">Guide and car</div>
                            <div className="gamma">{parseFloat(startprice).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className="alpha">Accommodation/activities</div>
                            <div className="gamma">{parseFloat(totalFee - startprice).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className={"alpha isgiftcard_" + validGiftcard}>GiftCard: {giftcard} </div>
                            <div className={"gamma isgiftcard_" + validGiftcard}>{discount}%</div>
                            <div className={"alpha total isgiftcard_" + validGiftcard}>Discount </div>
                            <div className={"gamma total isgiftcard_" + validGiftcard}>{parseFloat(discounttotal).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className="alpha total">Total</div>
                            <div className="gamma total">{parseFloat(totalFee).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                        </div>

                        <h3>Pick-up <span>Included in price</span></h3>
                        <select onChange={this.handlePickupChange}>
                            <option value="0" key={0}>I don't need pick-up</option>
                            {this.getPickup()}
                        </select>

                        <h3 className='hidethis'>Drop-off <span>Included in price</span></h3>
                        <select className='hidethis' onChange={this.handleDropoffChange}>
                            <option value="0" key={0}>I don't need drop-off</option>
                            {this.getPickup()}
                        </select>

                        <h3 className="giftcardh2">Gift Card</h3>
                        <div className="giftcard">
                            <input type="text" value={giftcard} onChange={e => this._handleGiftCard(e.target.value)} />
                            <button onClick={() => this._handleApplyCode()}>Apply</button>
                            <div className="giftcard_msg">{gift_msg}</div>
                        </div>

                        <input type="button" className={loading_btn} disabled={loading} value={loading ? 'Booking trip...' : 'Continue'}  onClick={() => this.addToBasket()} />
                        <button className='btn_cancel' onClick={() => this._handleCancel()}>Cancel</button>
                    </div>
                )
            } else if (userstage === 3) {
                return (
                    <div className='bokunarvel paymentform'>
                        <h3>{tourname}</h3>
                        {moment(v_dagur).format("dddd, MMMM D YYYY")} - {pickuptime}<br/><br/>
                        <strong>Pick-up</strong> from {pickupname}<br/>
                        {moment(v_dagur).format("dddd, MMMM D YYYY")} - {pickuptime}<br/><br/>
                        <h3>Order summary</h3>
                        <div className="basket">
                        <div className="alpha">Passagers</div>
                            <div className="gamma">{totalPassagers}</div>
                            <div className="alpha">Guide and car</div>
                            <div className="gamma">{parseFloat(startprice).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className="alpha">Accommodation/activities</div>
                            <div className="gamma">{parseFloat(totalFee - startprice).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className={"alpha hide" + ispickup}>Pick-up</div>
                            <div className={"gamma hide" + ispickup}>Included</div>
                            <div className={"alpha isgiftcard_" + validGiftcard}>GiftCard: {giftcard} </div>
                            <div className={"gamma isgiftcard_" + validGiftcard}>{discount}%</div>
                            <div className={"alpha total isgiftcard_" + validGiftcard}>Discount </div>
                            <div className={"gamma total isgiftcard_" + validGiftcard}>{parseFloat(discounttotal).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className="alpha total">Total</div>
                            <div className="gamma total">{parseFloat(totalFee).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                        </div>
                        <h2>Main traveller's contact details</h2>
                        <label>Name</label>
                        <input type="text" value={pax_name} className={pax_name_v} onChange={e => this._handleName(e.target.value)}/>
                        <div className="errorMessage">{pax_name_vt}</div>
                        <label>Your email address</label>
                        <input type="text" value={pax_email} className={pax_email_v} onChange={e => this._handleEmail(e.target.value)}/>
                        <div className="errorMessage">{pax_email_vt}</div>
                        <label>Phone number</label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={pax_phone}
                            className={pax_phone_v}
                            onChange={this._handlePhone}
                        />
                        <div className="errorMessage">{pax_phone_vt}</div>
                        <h2>PAYMENT METHOD</h2>
                        <input type="hidden" value={cardType} readOnly />
                        <label>Card Holder's name</label>
                        <input type="text" value={cardname}  placeholder="Card Holder's Name" onChange={e => this._handleCardName(e.target.value)} />
                        <label>Card number</label>
                        <input type="text" className={cardnumber_v} placeholder="0000 0000 0000 0000" id="cardnumber" value={this.formatCardNumber(cardnumber)} onChange={event => this.handleChange(event, "cardnumber")}  maxLength="19" />
                        <div className="errorMessage">{cardnumber_vt}</div>
                        <div className='f_expiry'>
                        <label htmlFor="cardMonth">Expiration Date</label>
                <select
                    className="month-input"
                    id="cardmonth"
                    value={cardmonth}
                    onChange={event => this.handleChange(event, "cardmonth")}
                >
                    {" "}
                    <option value="0" disabled>
                    Month
                    </option>
                    {Object.keys(MONTHS).map(monthKey => (
                    <option
                        key={monthKey}
                        value={monthKey}
                        disabled={ cardyear === CURRENT_YEAR && Number(monthKey) <= CURRENT_MONTH }
                    >
                        {MONTHS[monthKey]}
                    </option>
                    ))}
                </select>
                    <select
                        className="year-input"
                        id="cardyear"
                        value={cardyear}
                        onChange={event => this.handleChange(event, "cardyear")}
                    >
                        {" "}
                        <option value="0" disabled>
                        Year
                        </option>
                        {YEARS.map(year => ( <option key={year} value={year}>{year}</option> ))}
                    </select>
                </div>
                <div className='f_cvc'>
                        <label>CVC</label>
                        <input type="text" className="cvv-input" placeholder="123" id="cvv" value={cvv} onChange={event => this.handleChange(event, "cvv")} />
                    </div>
                    <div className='terms'>
                        <input type='checkbox' checked={agreement} onChange={event => this.handleChange(event, "terms")} /> I accept the <a href="https://www.buubble.com/terms/" target='_blank'>terms and conditions</a>
                        <div className='errorMessage'>{agreement_error}</div>
                    </div>
                    <br/>{FormMessage}<br/>
                    <input type="button" className={loading_btn} disabled={loading} value={loading ? 'getting payment...' : 'Pay Now'}  onClick={() => this.addToPayment()} />
                    <button className='btn_cancel' onClick={() => this._handleCancel()}>Cancel</button>
                </div>
                )
            } else {
                return (
                    <div className='bokunarvel paymentform'>
                        <h2>Congratulations</h2>
                        <h3 className='ticket'>Ticket: <strong>{bokunarcode}</strong></h3>
                        <strong>{pax_name}</strong><br/>
                        {pax_email}<br/>
                        {pax_phone}<br/><br/>
                        <h3>{tourname}</h3>
                        {moment(v_dagur).format("dddd, MMMM D YYYY")} - {pickuptime}<br/><br/>
                        <strong>Pick-up</strong> from {pickupname}<br/>
                        {moment(v_dagur).format("dddd, MMMM D YYYY")} - {pickuptime}<br/>
                        <h3>Order summary</h3>
                        <div className="basket">
                        <div className="alpha">Passagers</div>
                            <div className="gamma">{totalPassagers}</div>
                            <div className="alpha">Guide and car</div>
                            <div className="gamma">{parseFloat(startprice).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className="alpha">Accommodation / activities</div>
                            <div className="gamma">{parseFloat(totalFee - startprice).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className={"alpha hide" + ispickup}>Pick-up</div>
                            <div className={"gamma hide" + ispickup}>Included</div>
                            <div className={"alpha isgiftcard_" + validGiftcard}>GiftCard: {giftcard} </div>
                            <div className={"gamma isgiftcard_" + validGiftcard}>{discount}%</div>
                            <div className={"alpha total isgiftcard_" + validGiftcard}>Discount </div>
                            <div className={"gamma total isgiftcard_" + validGiftcard}>{parseFloat(discounttotal).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                            <div className="alpha total">Total</div>
                            <div className="gamma total">{parseFloat(totalFee).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ISK</div>
                        </div>
                    </div>
                )
            }
        } else {
            return (<div className='bokunarvel'><h3>Loading...</h3></div>)
        }
}

}