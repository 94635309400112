import React, { Component } from 'react'
import PropTypes from 'prop-types';

export class TripItems extends Component {

    static propTypes = {
        trip: PropTypes.object.isRequired
    }

    render() {
        const { title, acf, slug } = this.props.trip;
        return (
            <div>
                <h3><a href={"/trip/" + slug}>{fixtitle(title.rendered)}</a></h3><p><span>{currencyFormat(acf.from)}</span></p>
            </div>
        )
    }
}

function currencyFormat(num) {
    var number = parseFloat(num);
    if (isNaN(number)) {
        return "Price Missing";
    } else {
        return 'From: ' + number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ISK';
    }
}

function fixtitle(p) {
    var titletofixed = p;
    var fixedtitle = titletofixed.replace("&#8211;", '–').replace("&#038;", '&');
    return fixedtitle;
}
export default TripItems