import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Helmet} from "react-helmet";

import './App.css';
import Navbar from './components/Navbar';
import Trips from './pages/Trips';
import SelfDrive from './pages/SelfDrive';
import Trip from './pages/Trip';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Tours at buubble.com</title>
          <link rel="canonical" href="https://tours.buubble.com/" />
          <script src="https://kit.fontawesome.com/bf1969079f.js" crossorigin="anonymous"></script>
      </Helmet>
      <Router>
            <Navbar />
            <Routes>
                <Route exact path='/' element={<Trips />} />
                <Route exact path='/trip/selfdrive' element={<SelfDrive />}/>
                <Route path='/trip/:slug' element={<Trip />}/>
            </Routes>
            <Footer />
        </Router>
    </div>
  );
}

export default App;
