import React, { Component } from 'react'
import axios from 'axios';
import Trip from '../components/TripItems';
import FeaturedMedia from '../components/FeaturedMynd';

export class Trips extends Component {
    state = { trips: [], isLoaded: false }
    componentDidMount () {
        axios.get('https://cms.airmango.is/wp-json/wp/v2/trips/?categories=63')
        .then(res => this.setState({
            trips: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err))
    }

    render() {
        const {trips, isLoaded} = this.state;
        if (isLoaded) { 
            return (
                <div className='content'>
                    <div className="submenu">
                        <ul>
                            <li><span className='selected'><strong>All </strong> Private tours</span></li>
                        </ul>
                    </div>
                    <div className='projects_items'>
                    {trips.map(trip =>
                        <div className='item' key={trip.id}>
                            <FeaturedMedia trip={trip} linkur="trip" />
                            <Trip trip={trip} image="{trip.featured_media}"/> 
                        </div>
                    )}
                    </div>
                </div>
            )
        } else {
            return (<div>loading...</div>)
        }
    }
}

export default Trips