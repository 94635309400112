import React, { Component } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';

export class FeaturedMedia extends Component {
    state = { featured_media: '', isLoaded: false }
    static propTypes = { trip: PropTypes.object.isRequired }
    componentDidMount () {
        const { featured_media } = this.props.trip;
        
        const getMedia = axios.get('https://cms.airmango.is/wp-json/wp/v2/media/' + featured_media);
        Promise.all([getMedia]).then(res => {
            this.setState({ featured_media: res[0].data.media_details.sizes.large.source_url, isLoaded: true});
        });
    }

    render() {
        const { title, slug } = this.props.trip;
        const {featured_media, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <figure style={{ backgroundImage: 'url('+ featured_media +')' }}><a href={"/"+ this.props.linkur +"/" + slug}>{title.rendered}</a></figure>
            )
        } else {
            return (
                <figure></figure>
            )
        }
    }
}

export default FeaturedMedia